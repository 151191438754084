



















import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { saveAs } from "file-saver";

import { base64ToBlob } from "../../helpers/helpers";


@Component
export default class DocumentsDialog extends Vue {
    @Prop({ type: Boolean })
    private isDialogVisible!: boolean;
    @Prop({ type: Object, required: true })
    private policy!: any;

    private visible: boolean = false;
    private isLoading: boolean = true;

    private error: string = "";
    private documents: IPolicyDocument[] = [];


    private async loadDocuments() {
        this.isLoading = true;
        this.documents = [];
        this.error = "";

        try {
            const res = await this.$Api.Main.GetPolicyDocuments(this.policy.company, this.policy.id);

            if (res.error) throw new Error(res.error);

            this.documents = res.documents;
        } catch (error) {
            this.documents = [];
            this.error = error.message || error;
        }

        this.isLoading = false;
    }

    private async downloadDocument(document: IPolicyDocument) {
        const pdfBlob = base64ToBlob(document.content, document.type);
        saveAs(pdfBlob, document.name);
    }

    private closeDialog() {
        this.$emit("closeDialog");
    }

    @Watch("isDialogVisible")
    private onVisiblePropChange(val: boolean) {
        this.visible = val;
    }

    @Watch("visible")
    private onVisibleChange(val: boolean) {
        if (val) {
            this.loadDocuments();
        } else {
            this.closeDialog();
        }
    }
}
