var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Документы полиса",
        visible: _vm.visible,
        "destroy-on-close": "",
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _vm.error ? _c("i", [_vm._v(_vm._s(_vm.error))]) : _vm._e(),
      _vm.isLoading
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "doc-item",
            },
            [
              _c("div", { staticClass: "doc-name" }, [_vm._v("ПОЛИС.pdf")]),
              _c("el-button", { attrs: { type: "primary", size: "small" } }, [
                _vm._v("Скачать"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "documents-block" },
        _vm._l(_vm.documents, function (document, index) {
          return _c(
            "div",
            { key: index, staticClass: "doc-item" },
            [
              _c("div", { staticClass: "doc-name" }, [
                _vm._v(_vm._s(document.name)),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadDocument(document)
                    },
                  },
                },
                [_vm._v("Скачать")]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "dialog-btns" },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v("Закрыть"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }